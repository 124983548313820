import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Link, useRouteMatch } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { ServiceCategoryFragment } from '../../generated/graphql';
import ContentItem from '../ContentItem/ContentItem';
import getBackgroundGradient from '../../helpers/getBackgroundGradient';
import { IMatchParams } from '../../types/types';
import getTitle from '../../helpers/getTitle';
import Breakpoints from '../../themes/constants/breakpoints';
import Loader from '../Loader/Loader';
import BackgroundPicture from '../BackgroundPicture/BackgroundPicture';
import TitleLink from '../TitleLink/TitleLink';
import { PATH_PREFIX } from '../../routes/routes';

const Wrapper = styled.div<{ colored: boolean, backgroundGradient: string }>`
    z-index: 1;
    position: relative;
    background: ${({ colored, backgroundGradient, theme }) => {
        if (colored) {
            return backgroundGradient || theme.colors.mainColor;
        }
        return null;
    }};
    padding-bottom: 20px;
    :last-child {
        margin-bottom: -10px;
        padding-bottom: 30px;
    }
    @media only screen and (${Breakpoints.TABLET}) {
        background: none;
        padding-bottom: 26px;
        :last-child {
            padding-bottom: 36px;
        }
    }
    @media only screen and (${Breakpoints.LAPTOP_L}) {
        padding-bottom: 30px;
    }
`;

const Inner = styled.div`
    width: 100%;
    max-width: ${({ theme }) => theme.maxWidth};
    min-height: 200px;
    margin: 0 auto;
    box-sizing: border-box;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0 20px 0;
    @media only screen and (${Breakpoints.TABLET}) {
        display: grid;
        grid-template-columns: repeat(2, minmax(280px, 410px));
        grid-gap: 24px;
        justify-items: center;
        max-width: 840px;
        padding: 0 24px 26px;
        justify-content: unset;
    }
    @media only screen and (${Breakpoints.LAPTOP_L}) {
        grid-template-columns: repeat(4, minmax(280px, 400px));
        max-width: ${({ theme }) => theme.maxWidth};
        grid-gap: 40px;
        padding: 0 24px 30px;
    }
`;

const CategoryButton = styled(Link)`
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    width: 180px;
    max-width: 320px;
    background-color: #333;
    border-radius: 6px;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    color: #fff;
    margin: 10px auto;
    padding: 5px 10px;
    :active, :hover, :visited {
        color: #fff;
    }
`;

interface ICategorySectionProps {
    category: ServiceCategoryFragment;
    authorized: boolean;
    fetchMore?: (category: ServiceCategoryFragment) => void;
    colored: boolean;
}

const CategorySection = ({
    category, authorized, fetchMore, colored,
}: ICategorySectionProps) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        const loadMore = () => {
            if (contentRef?.current) {
                const contentBottom = +contentRef?.current?.offsetTop + +contentRef?.current?.offsetHeight;
                const windowScroll = window.scrollY + document.documentElement.clientHeight;

                if ((windowScroll >= contentBottom) && fetchMore) {
                    setShowLoader(true);
                    fetchMore(category);
                }
            }
        };
        loadMore();
        setShowLoader(false);
        if (category?.contents?.length && category.totalContent > category.contents.length) {
            window.addEventListener('scroll', loadMore);
        }
        return () => {
            window.removeEventListener('scroll', loadMore);
        };
    }, [category.contents?.length]);

    const routeMatch = useRouteMatch<IMatchParams>();

    const isMainPage = !routeMatch.params.alias;

    const categoryTitle = category.alias ? getTitle(category.alias) : '';
    const backgroundGradient = (category.alias && colored) ? getBackgroundGradient(category.alias) : '';
    const isTablet = useMediaQuery({ query: `(${Breakpoints.TABLET})` });

    return (
        <Wrapper colored={colored} backgroundGradient={backgroundGradient}>
            {
                isTablet && isMainPage && colored && (
                    <BackgroundPicture alias={category.alias} />
                )
            }
            <Inner>
                {category && isMainPage && !isTablet
                    && (
                        <TitleLink
                            name={category.name}
                            alias={category.alias}
                            isMainPage={isMainPage}
                            colored={colored}
                        />
                    )}
                {category && !isMainPage
                && (
                    <TitleLink
                        name={category.name}
                        alias={category.alias}
                        isMainPage={isMainPage}
                        colored={colored}
                    />
                )}
                {!isMainPage && category.contents && (
                    <ContentWrapper ref={contentRef}>
                        {category.contents
                            .map((item, index) => {
                                const bigSize = index <= 3 || (index >= 8 && index <= 11);
                                return (
                                    <ContentItem
                                        item={item}
                                        key={item.id}
                                        bigSize={bigSize}
                                        authorized={authorized}
                                    />
                                );
                            })}
                    </ContentWrapper>
                )}
                {isMainPage && category.contents && (
                    <ContentWrapper>
                        {category.contents
                            .slice(0, 8)
                            .map((item, index) => {
                                const bigSize = index <= 3;
                                return (
                                    <ContentItem
                                        item={item}
                                        key={item.id}
                                        bigSize={bigSize}
                                        authorized={authorized}
                                    />
                                );
                            })}
                    </ContentWrapper>
                )}
                {category.alias && isMainPage && (
                    <CategoryButton to={`${PATH_PREFIX}/${category.alias}`}>
                        {categoryTitle}
                    </CategoryButton>
                )}
                {showLoader && (
                    <Loader />
                )}
            </Inner>
        </Wrapper>
    );
};

CategorySection.defaultProps = {
    fetchMore: null,
};

export default CategorySection;
