import React from 'react';
import styled from 'styled-components';
import Breakpoints from '../../themes/constants/breakpoints';
import { useCategoryByIdQuery } from '../../generated/graphql';
import ContentItem from '../ContentItem/ContentItem';

const Wrapper = styled.div`
    @media only screen and (${Breakpoints.LAPTOP}) {
        width: 26%;
        min-width: 350px;
        display: flex;
        flex-direction: column;
    }
`;

const ScrollWrapper = styled.div`
    padding: 20px 0 0;
    flex: 1;
    width: 100%;
    @media only screen and (${Breakpoints.LAPTOP}) {
        overflow-y: scroll;
        position: relative;
        ::-webkit-scrollbar {
            display: none;
        }
    }
`;

const Inner = styled.div`
    margin: 0 auto;
    padding: 0 20px;
    @media only screen and (${Breakpoints.TABLET}) {
        display: grid;
        grid-template-columns: repeat(2, minmax(280px, 400px));
        grid-gap: 24px;
        justify-items: center;
        max-width: 840px;
        padding: 0 0 26px;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        display: block;
        padding: 20px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 20px;
        left: 0;
    }
`;

const Title = styled.h3`
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: #999999;
    margin: 20px 0 0;
    @media only screen and (${Breakpoints.TABLET}) {
        font-size: 24px;
        margin: 36px 0 0;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        margin: 27px 0 4px;
    }
`;

interface IAdditionalContent {
    categoryId: string;
    contentId: string;
}

const AdditionalContent = ({ categoryId, contentId }: IAdditionalContent) => {
    const { data } = useCategoryByIdQuery({
        variables: {
            id: categoryId,
            limit: 8,
            offset: 0,
        },
    });

    const contents = data?.point?.service.category?.contents?.length ? data?.point?.service.category?.contents
        .filter((item) => item.id !== contentId) : [];
    return (
        <Wrapper>
            <Title>Ещё по теме</Title>
            <ScrollWrapper>
                <Inner>
                    {!!contents.length
                    && contents.slice(0, 7).map((content, index) => {
                        let bigSize = false;
                        if (index <= 2) {
                            bigSize = true;
                        }
                        return (
                            <ContentItem
                                key={content.id}
                                item={content}
                                bigSize={bigSize}
                                additional
                                authorized={!!data?.point?.verify}
                            />
                        );
                    })}
                </Inner>
            </ScrollWrapper>
        </Wrapper>
    );
};

export default AdditionalContent;
