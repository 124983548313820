import React, { ReactElement } from 'react';
import { ThemeProvider as Provider } from 'styled-components';
import themes from '../constants/themes';
import MainCSS from '../styles/MainCSS';
import 'normalize.css';
import '../../fonts/stylesheet.css';
import 'swiper/swiper-bundle.min.css';

// Interfaces
interface IThemeProvider {
    children: ReactElement | ReactElement[]
}

// Component
const ThemeProvider = (props: IThemeProvider) => {
    const { children } = props;

    // Render
    return (
        <Provider theme={themes}>
            <>
                <MainCSS />
                {children}
            </>
        </Provider>
    );
};

export default ThemeProvider;
