import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Router from '../Router/Router';
import '../../fonts/stylesheet.css';
import { useCompactQuery } from '../../generated/graphql';
import Loader from '../Loader/Loader';
import cross from '../../assets/Icons/cross.svg';
import '@b3w/pp-portal/dist/portal-bundle.css';

const AppWrapper = styled.div`
    min-width: 320px;
    max-width: 100%;
    min-height: 100vh;
    margin: 0 auto;
    background-color: #fff;
    font-family: Pragmatica, sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

function App() {
    const { data, loading } = useCompactQuery();

    if (loading) return <Loader />;

    return (
        <AppWrapper>
            <Helmet>
                <link rel="preload" href={cross} as="image" />
            </Helmet>
            <Router data={data} />
        </AppWrapper>
    );
}

export default App;
