import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import CategorySection from '../../components/CategorySection/CategorySection';
import Page404 from '../Page404/Page404';
import {
    useCompactQuery,
    ServiceCategoryFragment,
    useCategoryByIdLazyQuery,
    LoadMoreCategoryDocument,
} from '../../generated/graphql';
import Loader from '../../components/Loader/Loader';
import Breakpoints from '../../themes/constants/breakpoints';
import ErrorPage from '../ErrorPage/ErrorPage';

const Wrapper = styled.div`
    padding-top: ${({ theme }) => theme.paddings.paddingTop};
    flex: 1;
    @media only screen and (${Breakpoints.TABLET}) {
        padding-top: 70px;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        padding-top: 80px;
    }
`;

interface IMatchParams {
    alias: string;
}

const CategoryPage = () => {
    const routeMatch = useRouteMatch<IMatchParams>();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [routeMatch.params.alias]);

    const [
        fetchCategoryById,
        {
            data: categoryData, fetchMore, loading: categoryLoading,
        },
    ] = useCategoryByIdLazyQuery();

    const { data, loading, error } = useCompactQuery();

    const id = data?.point?.service.categories
        ?.find((item) => item.alias === routeMatch.params.alias)?.id;

    useEffect(() => {
        if (id) {
            fetchCategoryById({
                variables: {
                    id,
                    limit: 8,
                    offset: 0,
                },
            });
        }
    }, [routeMatch.params.alias]);

    if (loading || categoryLoading) return <Loader />;

    if (!id) return <Page404 />;

    if (error) return <ErrorPage />;

    const fetchMoreCallback = async (category: ServiceCategoryFragment) => {
        if (fetchMore) {
            await fetchMore({
                query: LoadMoreCategoryDocument,
                variables: {
                    id: category.id,
                    limit: 8,
                    offset: (category.contents) ? category.contents.length : 0,
                },
            });
        }
    };

    return (
        <Wrapper>
            {
                categoryData?.point?.service.category && (
                    <CategorySection
                        category={categoryData?.point?.service.category}
                        authorized={!!data?.point?.verify}
                        fetchMore={fetchMoreCallback}
                        colored={false}
                    />
                )
            }
        </Wrapper>
    );
};

export default CategoryPage;
