import React, {
    useEffect, useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import styled, { keyframes } from 'styled-components/macro';
import promoImg1 from '../../assets/images/promo-1.png';
import promoImg2 from '../../assets/images/promo-2.png';
import promoImg3 from '../../assets/images/promo-3.png';
import { ReactComponent as PlayIcon1 } from '../../assets/Icons/promo-icon.svg';
import { ReactComponent as PlayIcon2 } from '../../assets/Icons/promo-play.svg';
import Breakpoints from '../../themes/constants/breakpoints';

const flash = keyframes`
  0% {
    left: -150%;
  }
  20% {
    left: 100%
  }
  100% {
    left: 150%
  }
`;

const Container = styled.div`
    width: 100%;
    max-width: 420px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 20px 20px 0;
    -webkit-tap-highlight-color: transparent;
    @media only screen and (${Breakpoints.TABLET}) {
        max-width: 888px;
        padding: 0 24px 26px;
    }
    @media only screen and (${Breakpoints.LAPTOP_L}) {
        max-width: 1440px;
    }
`;

const Inner = styled.div<{ image: string, imageType1?: boolean }>`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    position: relative;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    height: 150px;
    border-radius: 12px;
    // background-image: ${({ image }) => `url("${image}")`};
    //background-size: cover;
    //background-repeat: no-repeat;
    // background-position: ${({ imageType1 }) => (imageType1 ? '50% 25%' : '50% 17%')};
    cursor: pointer;
    padding: 20px;
    background: #eee;
    @media only screen and (${Breakpoints.TABLET}) {
        padding: 0 50px;
    }
    @media only screen and (${Breakpoints.LAPTOP_L}) {
        padding: 30px 100px;
    }
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%) skewX(-30deg);
      display: block;
      height: 100%;
      width: 100%;
      background-image: linear-gradient(90deg, rgba(0,0,0,0), hsla(0,0%,100%,.3) 90%, rgba(0,0,0,0));
      animation: ${flash} 5s ease-in-out infinite;
      z-index: 100;
      padding: 0 10px;
    }
`;

const Text = styled.span<{ rightAlign?: boolean }>`
    font-size: 20px;
    font-weight: 700;
    font-family: Roboto, sans-serif;
    color: #fff;
    text-transform: uppercase;
    width: 160px;
    text-align: ${({ rightAlign }) => rightAlign && 'right'};
    @media only screen and (${Breakpoints.TABLET}) {
        font-size: 29px;
        width: 260px;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        width: 390px
    }
    @media only screen and (${Breakpoints.LAPTOP_L}) {
        font-size: 33px;
        width: 450px;
    }
`;

function PromoBanner() {
    const [, setRandomItem] = useState<JSX.Element | null>(null);
    const { pathname } = useLocation();

    const banner1 = (
        <Inner image={promoImg1} />
    );

    const banner2 = (
        <Inner image={promoImg2} imageType1>
            <Text>
                читай под
                {' '}
                <br />
                {' '}
                музыку
                <br />
                {' '}
                тут&nbsp;
                <PlayIcon1 />
            </Text>
        </Inner>
    );

    const banner3 = (
        <Inner image={promoImg3}>
            <Text>
                читай под
                {' '}
                <br />
                {' '}
                музыку
            </Text>
            <PlayIcon2 />
        </Inner>
    );

    const items = [banner1, banner2, banner3];

    useEffect(() => {
        const getRandomItem = () => {
            const shuffledItems = [...items].sort(() => 0.5 - Math.random());
            setRandomItem(shuffledItems[0]);
        };

        getRandomItem();
    }, [pathname]);

    return (
        <Container>
            <div aria-hidden="true">
                {banner1}
            </div>
        </Container>
    );
}

export default PromoBanner;
