import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Lazy } from 'swiper';
import { useSliderDataQuery, SliderContentFragment } from '../../generated/graphql';
import ContentItem from '../ContentItem/ContentItem';
import Breakpoints from '../../themes/constants/breakpoints';

const Wrapper = styled.div`
    width: 100%;
    position: relative;
    @media only screen and (${Breakpoints.LAPTOP_L}) {
        &:before {
            content: '';
            position: absolute;
            z-index: 2;
            height: 100%;
            width: calc(50% - 700px);
            top: 0;
            bottom: 0;
            left: 0;
            background: rgba(38, 31, 75, 0.5);
            backdrop-filter: blur(3px);
            box-shadow: inset -5px -5px 10px rgba(38, 31, 75, 0.5);
        }
        &:after {
            content: '';
            position: absolute;
            z-index: 1;
            height: 100%;
            width: calc(50% - 700px);
            top: 0;
            bottom: 0;
            right: 0;
            background: rgba(38, 31, 75, 0.5);
            backdrop-filter: blur(3px);
            box-shadow: inset -5px 5px 10px rgba(38, 31, 75, 0.5);
        }
    }
    @media only screen and (${Breakpoints.DESKTOP_M}) {
        &:before, &:after {
            background: linear-gradient(90deg, #261F4B 10%, rgba(38, 31, 75, 0) 100%);
        }
        &:after {
            transform: rotate(-180deg);
        }
    }
`;

SwiperCore.use([Autoplay, Lazy]);

const Slider = () => {
    const { data } = useSliderDataQuery({
        variables: {
            filter: {
                typeName: 'sf_any_content',
            },
        },
    });
    return (
        <Wrapper>
            {
                data?.point?.service?.contents?.length ? (
                    <Swiper
                        slidesPerView="auto"
                        loop
                        lazy={{
                            loadPrevNext: true,
                            loadPrevNextAmount: 10,
                        }}
                        autoplay={{
                            delay: 5000,
                        }}
                        breakpoints={{
                            1920: {
                                lazy: {
                                    loadPrevNextAmount: 10,
                                },
                            },
                        }}
                    >
                        {data.point.service.contents.map((content: SliderContentFragment) => (
                            <SwiperSlide key={content.id}>
                                <ContentItem
                                    authorized={!!data?.point?.verify}
                                    bigSize
                                    inSlider
                                    item={content}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                ) : null
            }
        </Wrapper>
    );
};

export default Slider;
