import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import Breakpoints from '../../themes/constants/breakpoints';
import { PATH_PREFIX } from '../../routes/routes';

const Wrapper = styled.div`
    padding: ${({ theme }) => theme.paddings.paddingTop} 20px 10px;
    flex: 1;
    max-width: ${({ theme }) => theme.maxWidth};
    margin: 0 auto;
    @media only screen and (${Breakpoints.TABLET}) {
        padding: 70px 20px 10px;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        padding: 80px 20px 10px;
    }
`;

const TextWrapper = styled.div`
    color: #4c4c4e;
    font-size: 16px;
    width: 100%;
    text-align: justify;
    padding-top: 10px;
    h1 {
        font-size: 20px;
    }
    h2, h3 {
        font-size: 18px;
    }
    a {
        color: #4c4c4e;
    }
    @media only screen and (${Breakpoints.TABLET}) {
        font-size: 18px;
        padding-top: 10px;
        h1 {
            font-size: 24px;
        }
        h2, h3 {
            font-size: 20px;
        }
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        font-size: 18px;
        h1 {
            font-size: 28px;
        }
        h2, h3 {
            font-size: 24px;
        }
    }
`;

const OfferPage = () => {
    const [offer, setOffer] = useState('');

    useEffect(() => {
        axios.get(`${PATH_PREFIX}/text/rb-mts-health/oferta/1`)
            .then((res) => {
                if (res.data) {
                    setOffer(res.data);
                }
            })
            .catch(() => {});
    }, []);

    return (
        <Wrapper>
            <TextWrapper dangerouslySetInnerHTML={{ __html: offer }} />
        </Wrapper>
    );
};

export default OfferPage;
