const themes = {
    colors: {
        mainColor: '#EC5500',
        text: '#766EA9',
    },
    maxWidth: '1440px',
    paddings: {
        paddingTop: '110px',
    },
    backgroundGradients: {
        food: 'linear-gradient(178.95deg, #BE64BC 0.06%, #D28A9A 34.41%, #EDC9B8 65.65%, #FEFBC6 100%)',
        family: `linear-gradient(178.95deg,
            #E9A798 0.06%, #B5ACC8 21.54%, #8EC6EC 45.33%, #82D3E9 75.01%, #6FACEA 100%)`,
        style: 'linear-gradient(178.95deg, #F6EAF8 0.06%, #D6D4EB 31.81%, #C3CEEB 63.04%, #E6EBFB 100%)',
    },
};

export default themes;
