import React from 'react';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { offsetLimitPagination } from '@apollo/client/utilities';
import App from '../App/App';
import ThemeProvider from '../../themes/provider/ThemeProvider';
import { PATH_PREFIX } from '../../routes/routes';

export const client = new ApolloClient({
    uri: `${PATH_PREFIX}/api/query`,
    connectToDevTools: true,
    cache: new InMemoryCache({
        typePolicies: {
            Preview: {
                keyFields: ['id'],
            },
            Category: {
                keyFields: ['id'],
                fields: {
                    contents: offsetLimitPagination(),
                },
            },
            Tag: {
                keyFields: ['id'],
                fields: {
                    contents: offsetLimitPagination(),
                },
            },
            Service: {
                keyFields: ['id'],
                fields: {
                    contents: offsetLimitPagination(),
                },
            },
            Content: {
                keyFields: ['id'],
            },
        },
    }),
});

const Root = () => (
    <ApolloProvider client={client}>
        <ThemeProvider>
            <App />
        </ThemeProvider>
    </ApolloProvider>
);

export default Root;
