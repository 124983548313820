import React from 'react';
import styled from 'styled-components';
import Breakpoints from '../../themes/constants/breakpoints';
import FooterMenu from './FooterMenu';
import { ServiceQuery } from '../../generated/graphql';

const FooterWrapper = styled.div`
    width: 100%;
    background-color: #2d2d2d;
    border-radius: 10px 10px 0 0;
    padding: 10px 20px 20px;
    margin: 0 auto;
    box-sizing: border-box;
    z-index: 1;
    a {
        color: inherit;
        :active, :visited, :hover {
            color: inherit;
        }
    }
    @media only screen and (${Breakpoints.TABLET}) {
        padding: 25px;
    }
`;

const FooterInner = styled.div`
    max-width: ${({ theme }) => theme.maxWidth};
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: #e4e4e4;
    text-align: justify;
    margin: 0 auto;
    a {
        color: inherit;
        :active, :visited, :hover {
            color: inherit;
        }
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        display: flex;
        flex-direction: row;
    }
`;

interface IFooterProps {
    data: ServiceQuery | undefined
}

const Footer = ({ data }: IFooterProps) => (
    <FooterWrapper>
        <FooterInner>
            {
                data?.point?.service.categories
                && <FooterMenu categories={data?.point?.service.categories} />
            }
        </FooterInner>
    </FooterWrapper>
);

export default Footer;
