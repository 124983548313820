import React from 'react';
import {
    BrowserRouter,
    Switch,
    Route,
} from 'react-router-dom';
import { Statistic } from '@b3w/react-statistic';
import MainPage from '../../pages/MainPage/MainPage';
import ContentPage from '../../pages/ContentPage/ContentPage';
import CategoryPage from '../../pages/CategoryPage/CategoryPage';
import OfferPage from '../../pages/OfferPage/OfferPage';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { ServiceQuery } from '../../generated/graphql';
import { PATH_PREFIX } from '../../routes/routes';

interface IRouterProps {
    data: ServiceQuery | undefined
}

const Router = ({ data }: IRouterProps) => (
    <BrowserRouter>
        <Header data={data} />
        <Statistic
            path={`${PATH_PREFIX}/referer/statistic`}
            from
            timeoutMinutes={1}
        />
        <Switch>
            <Route key="offer" path={`${PATH_PREFIX}/user/conditions`} component={OfferPage} />
            <Route key="content" path={`${PATH_PREFIX}/:alias/:id`} component={ContentPage} />
            <Route key="category" path={`${PATH_PREFIX}/:alias`} component={CategoryPage} />
            <Route exact key="main" path={PATH_PREFIX} component={MainPage} />
        </Switch>
        <Footer data={data} />
    </BrowserRouter>
);

export default Router;
