import React from 'react';
import styled from 'styled-components';
import Button from '../../components/Button/Button';
import { PATH_PREFIX } from '../../routes/routes';
import Breakpoints from '../../themes/constants/breakpoints';

const Wrapper = styled.div`
    padding: ${({ theme }) => theme.paddings.paddingTop} 20px 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media only screen and (${Breakpoints.TABLET}) {
        padding: 70px 20px 0;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        padding: 80px 20px 0;
    }
`;

const Info = styled.div`
    text-align: center;
    margin-bottom: 20px;
    span {
        font-size: 28px;
        display: block;
        margin-bottom: 20px;
        font-weight: 500;
    }
`;

const HomeLink = styled(Button)`
    background-color: ${({ theme }) => theme.colors.mainColor};
`;

const Page404 = () => (
    <Wrapper>
        <Info>
            <span>404</span>
            Страница не найдена. Попробуйте еще раз.
        </Info>
        <HomeLink link={PATH_PREFIX} text="На главную" />
    </Wrapper>
);

export default Page404;
