import React, { useEffect, useState } from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import { useContentByIdQuery, useContentIdByCategoryLazyQuery } from '../../generated/graphql';
import { IMatchParams } from '../../types/types';
import Breakpoints from '../../themes/constants/breakpoints';
import ErrorPage from '../ErrorPage/ErrorPage';
import Page404 from '../Page404/Page404';
import Loader from '../../components/Loader/Loader';
import AdditionalContent from '../../components/AdditionalContent/AdditionalContent';
import TitleLink from '../../components/TitleLink/TitleLink';
import homeIcon from '../../assets/Icons/home.svg';
import { PATH_PREFIX } from '../../routes/routes';

const Wrapper = styled.div`
    padding: ${({ theme }) => theme.paddings.paddingTop} 0 0;
    max-width: ${({ theme }) => theme.maxWidth};
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    flex: 1;
    display: flex;
    flex-direction: column;
    @media only screen and (${Breakpoints.TABLET}) {
        padding: 70px 0 0;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        padding: 80px 0 0;
    }
`;

const MainContent = styled.div`
    @media only screen and (${Breakpoints.LAPTOP}) {
        flex: 1;
    }
`;

const MainContentInner = styled.div`
    padding: 20px;
    box-shadow: 0 10px 20px rgb(0 0 0 / 25%);
    border-radius: 10px;
    @media only screen and (${Breakpoints.LAPTOP}) {
        box-shadow: none;
        padding: 0 20px 40px 0;
    }
`;

const ContentText = styled.div`
    font-size: 14px;
    line-height: 20px;
    h3 {
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
    }
    img, video {
        width: 100%;
        border-radius: 10px;
    }
    a {
        color: #000;
    }
    @media only screen and (${Breakpoints.TABLET}) {
        font-size: 18px;
        line-height: 24px;
        h3 {
            font-size: 32px;
            line-height: 1;
        }
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        box-shadow: none;
        font-size: 20px;
        line-height: 30px;
        h3 {
            font-size: 32px;
            line-height: 1;
        }
    }
`;

const ContentWrapper = styled.div`
    @media only screen and (${Breakpoints.TABLET}) {
        padding: 0 24px;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        display: flex;
        flex-direction: row;
        flex: 1;
    }
`;

const Title = styled.h1`
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 20px;
    @media only screen and (${Breakpoints.TABLET}) {
        font-size: 32px;
    }
`;

const Description = styled.h2`
    font-size: 14px;
    font-weight: 400;
    color: #8A8D8F;
    margin: 0 0 20px;
    text-align: start;
    @media only screen and (${Breakpoints.TABLET}) {
        font-size: 18px;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        font-size: 20px;
    }
`;

const Image = styled.img`
    width: 100%;
    margin-bottom: 24px;
    border-radius: 10px;
`;

const LinksWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
`;

const HomeLink = styled(Link)`

`;

const HomeButton = styled.div`
    background: url(${homeIcon}) 50% 50% no-repeat #333333;
    height: 30px;
    width: 30px;
    border-radius: 6px;
`;

const ArticleLink = styled(Link)`
    background-color: ${({ theme }) => theme.colors.mainColor};
    height: 30px;
    min-width: 147px;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    color: #fff;
    font-size: 12px;
    text-decoration: none;
`;

const VideoPlayer = styled.video`
    width: 100%;
    border-radius: 10px;
`;

const ContentPage = () => {
    const routeMatch = useRouteMatch<IMatchParams>();

    const { alias, id } = routeMatch.params;
    const [text, setText] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [id]);

    const getContentText = (link: string | undefined, type: string | undefined) => {
        if (link && ((type === 'html' || type === 'sf_section'))) {
            axios.get(`${PATH_PREFIX}/gate/p/${link}`)
                .then((res) => {
                    if (res.data) {
                        setText(res.data);
                    }
                })
                .catch(() => {});
        }
    };

    const [fetchContentId, { data: contentIdData }] = useContentIdByCategoryLazyQuery({
        fetchPolicy: 'no-cache',
    });

    const { data, loading, error } = useContentByIdQuery({
        variables: {
            id,
        },
        onCompleted: (res) => {
            const link = res?.point?.service.content?.link;
            const flowURL = res?.point?.flowURL || 'https://www.google.by';
            const flowMark = flowURL.includes('?') ? '&' : '?';
            const currentPath = window.location.pathname;
            const clearPath = currentPath.startsWith(PATH_PREFIX)
                ? `${window.location.pathname}`.replace(PATH_PREFIX, '')
                : currentPath;
            const contentUrlValue = encodeURIComponent(clearPath) + encodeURIComponent(window.location.search);
            const sourceServiceStr = 'source=service';
            const sourceService = flowURL.includes(sourceServiceStr) ? '' : `&${sourceServiceStr}`;
            const flow = `${flowURL}${flowMark}content_url=${contentUrlValue}${sourceService}`;
            if (res?.point?.service.content?.type !== 'html' && !res?.point?.verify) {
                window.location.href = flow;
            }
            getContentText(link, data?.point?.service.content?.type);
            if (res.point?.service.content?.category.id) {
                fetchContentId({
                    variables: {
                        id: res.point?.service.content?.category.id,
                        limit: res.point?.service.content.category.totalContent,
                        offset: 0,
                        filter: {
                            typeName: res.point?.service.content.type,
                        },
                    },
                });
            }
        },
    });

    if (loading) return <Loader />;

    if (!data?.point?.service.content?.link) return <Page404 />;

    if (error) return <ErrorPage />;

    const content = data?.point?.service.content;
    const categoryId = data?.point?.service.content?.category.id;
    const imageSrc = content.previews?.length ? `${PATH_PREFIX}/gate/f/${content.previews[0].link}` : '';

    const randomContentIndex = contentIdData?.point?.service.category?.contents?.length
        && Math.ceil(contentIdData?.point?.service.category?.contents?.length
        * Math.random());
    const randomContent = randomContentIndex
        && contentIdData?.point?.service.category?.contents?.length
        && contentIdData?.point?.service.category?.contents[randomContentIndex];
    const randomContentId = randomContent && randomContent.id;
    const randomContentLink = randomContentId && `${PATH_PREFIX}/${alias}/${randomContentId}`;

    return (
        <Wrapper>
            {
                (data?.point?.verify || data?.point?.service.content?.type === 'html') ? (
                    <>
                        <ContentWrapper>
                            <MainContent>
                                <TitleLink alias={alias} name={content?.category.name} isContentPage />
                                <MainContentInner>
                                    <Image src={imageSrc} alt="" />
                                    <Title>
                                        {content?.name}
                                    </Title>
                                    <Description>
                                        {content?.description}
                                    </Description>
                                    {(data?.point?.service.content?.type === 'html'
                                        || data?.point?.service.content?.type === 'sf_section')
                                        ? (
                                            <ContentText dangerouslySetInnerHTML={{ __html: text }} />
                                        )
                                        : (
                                            <VideoPlayer
                                                controls
                                                preload="metadata"
                                                src={`${PATH_PREFIX}/gate/p/${data?.point.service.content.link}`}
                                            >
                                                <track
                                                    kind="captions"
                                                />
                                            </VideoPlayer>
                                        )}

                                    <LinksWrapper>
                                        <HomeLink to={PATH_PREFIX}>
                                            <HomeButton />
                                        </HomeLink>
                                        <ArticleLink
                                            to={randomContentLink || '#'}
                                        >
                                            Следующая статья
                                        </ArticleLink>
                                    </LinksWrapper>
                                </MainContentInner>
                            </MainContent>
                            {categoryId && <AdditionalContent categoryId={categoryId} contentId={id} />}
                        </ContentWrapper>
                    </>
                ) : (
                    <></>
                )
            }
        </Wrapper>
    );
};

export default ContentPage;
