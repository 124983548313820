enum CategoryTitles {
    FOOD = 'Всё о питании',
    HEALTH = 'Всё о здоровье',
    BEAUTY = 'Всё о красоте',
    FAMILY = 'Всё о семье',
    SPORT = 'Всё о спорте',
    STYLE = 'Всё о стиле',
}

export default CategoryTitles;
