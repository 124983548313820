import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Category = {
  __typename?: 'Category';
  alias: Scalars['String'];
  contents?: Maybe<Array<Content>>;
  id: Scalars['ID'];
  localizations?: Maybe<Array<I18nCategory>>;
  name: Scalars['String'];
  priority: Scalars['Int'];
  totalContent: Scalars['Int'];
  type: Scalars['String'];
};


export type CategoryContentsArgs = {
  filter?: Maybe<ContentFilter>;
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  paramFilter?: Maybe<Array<ParamFilter>>;
};


export type CategoryLocalizationsArgs = {
  lang: Array<Scalars['String']>;
};

export type Content = {
  __typename?: 'Content';
  category: Category;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  link: Scalars['String'];
  localizations?: Maybe<Array<I18nContent>>;
  metaContentParams?: Maybe<Array<MetaContentParam>>;
  name: Scalars['String'];
  previews?: Maybe<Array<Preview>>;
  priority: Scalars['Int'];
  protected: Scalars['Boolean'];
  tags?: Maybe<Array<Tag>>;
  type: Scalars['String'];
};


export type ContentLocalizationsArgs = {
  lang: Array<Scalars['String']>;
};

export type ContentFilter = {
  typeName?: Maybe<Scalars['String']>;
};

export type I18nCategory = {
  __typename?: 'I18nCategory';
  id: Scalars['ID'];
  lang: Scalars['String'];
  name: Scalars['String'];
};

export type I18nContent = {
  __typename?: 'I18nContent';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lang: Scalars['String'];
  link: Scalars['String'];
  name: Scalars['String'];
};

export type I18nMetaContentParam = {
  __typename?: 'I18nMetaContentParam';
  id: Scalars['ID'];
  lang: Scalars['String'];
  value: Scalars['String'];
};

export type I18nTag = {
  __typename?: 'I18nTag';
  displayName: Scalars['String'];
  id: Scalars['ID'];
  lang: Scalars['String'];
};

export enum Language {
  Am = 'am',
  Base = 'base',
  By = 'by',
  En = 'en',
  Kz = 'kz',
  Ru = 'ru',
  Ua = 'ua',
  Uz = 'uz'
}

export type MetaContentParam = Param & {
  __typename?: 'MetaContentParam';
  id: Scalars['ID'];
  localizations?: Maybe<Array<I18nMetaContentParam>>;
  name: Scalars['String'];
  value: Scalars['String'];
};


export type MetaContentParamLocalizationsArgs = {
  lang: Array<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  deactivateSubs?: Maybe<Scalars['Boolean']>;
};

export type Param = {
  id: Scalars['ID'];
  name: Scalars['String'];
  /** Base64 encoded JSON object */
  value: Scalars['String'];
};

export type ParamFilter = {
  paramName: Scalars['String'];
  value: Scalars['String'];
};

export type Point = {
  __typename?: 'Point';
  flowURL?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  service: Service;
  sskey: Scalars['String'];
  verify?: Maybe<Scalars['Boolean']>;
};

export type Preview = {
  __typename?: 'Preview';
  id: Scalars['ID'];
  link: Scalars['String'];
  priority: Scalars['Int'];
  type: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  point?: Maybe<Point>;
};

export enum SearchField {
  Description = 'description',
  Name = 'name'
}

export type Service = {
  __typename?: 'Service';
  alias: Scalars['String'];
  categories?: Maybe<Array<Category>>;
  category?: Maybe<Category>;
  content?: Maybe<Content>;
  contents?: Maybe<Array<Content>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  search?: Maybe<Array<Content>>;
  tag?: Maybe<Tag>;
  tags?: Maybe<Array<Tag>>;
  totalContent: Scalars['Int'];
};


export type ServiceCategoryArgs = {
  id: Scalars['ID'];
};


export type ServiceContentArgs = {
  id: Scalars['ID'];
};


export type ServiceContentsArgs = {
  filter?: Maybe<ContentFilter>;
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  paramFilter?: Maybe<Array<ParamFilter>>;
};


export type ServiceSearchArgs = {
  fields?: Array<SearchField>;
  lang?: Array<Language>;
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  query: Scalars['String'];
};


export type ServiceTagArgs = {
  id: Scalars['ID'];
};


export type ServiceTagsArgs = {
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
};

export type Tag = {
  __typename?: 'Tag';
  contents?: Maybe<Array<Content>>;
  displayName: Scalars['String'];
  id: Scalars['ID'];
  localizations?: Maybe<Array<I18nTag>>;
  name: Scalars['String'];
  priority: Scalars['Int'];
  totalContent: Scalars['Int'];
};


export type TagContentsArgs = {
  filter?: Maybe<ContentFilter>;
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  paramFilter?: Maybe<Array<ParamFilter>>;
};


export type TagLocalizationsArgs = {
  lang: Array<Scalars['String']>;
};

export type ServiceQueryVariables = Exact<{
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
}>;


export type ServiceQuery = (
  { __typename?: 'Query' }
  & { point?: Maybe<(
    { __typename?: 'Point' }
    & Pick<Point, 'id' | 'flowURL' | 'sskey' | 'verify'>
    & { service: (
      { __typename?: 'Service' }
      & Pick<Service, 'id'>
      & { categories?: Maybe<Array<(
        { __typename?: 'Category' }
        & ServiceCategoryFragment
      )>> }
    ) }
  )> }
);

export type CompactQueryVariables = Exact<{ [key: string]: never; }>;


export type CompactQuery = (
  { __typename?: 'Query' }
  & { point?: Maybe<(
    { __typename?: 'Point' }
    & Pick<Point, 'id' | 'flowURL' | 'sskey' | 'verify'>
    & { service: (
      { __typename?: 'Service' }
      & Pick<Service, 'id'>
      & { categories?: Maybe<Array<(
        { __typename?: 'Category' }
        & CompactCategoryFragment
      )>> }
    ) }
  )> }
);

export type LoadMoreCategoryQueryVariables = Exact<{
  id: Scalars['ID'];
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
}>;


export type LoadMoreCategoryQuery = (
  { __typename?: 'Query' }
  & { point?: Maybe<(
    { __typename?: 'Point' }
    & Pick<Point, 'id'>
    & { service: (
      { __typename?: 'Service' }
      & Pick<Service, 'id'>
      & { category?: Maybe<(
        { __typename?: 'Category' }
        & ServiceCategoryFragment
      )> }
    ) }
  )> }
);

export type CompactCategoryFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'alias' | 'name' | 'totalContent' | 'priority'>
);

export type ServiceCategoryFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'alias' | 'name' | 'totalContent' | 'priority'>
  & { contents?: Maybe<Array<(
    { __typename?: 'Content' }
    & ContentFragment
  )>> }
);

export type ContentFragment = (
  { __typename?: 'Content' }
  & Pick<Content, 'id' | 'description' | 'link' | 'name' | 'type' | 'priority'>
  & { category: (
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'alias' | 'name' | 'totalContent'>
  ), tags?: Maybe<Array<(
    { __typename?: 'Tag' }
    & TagFragment
  )>>, previews?: Maybe<Array<(
    { __typename?: 'Preview' }
    & PreviewFragment
  )>> }
);

export type PreviewFragment = (
  { __typename?: 'Preview' }
  & Pick<Preview, 'id' | 'link' | 'type'>
);

export type TagFragment = (
  { __typename?: 'Tag' }
  & Pick<Tag, 'id' | 'displayName' | 'priority'>
);

export type CategoryByIdQueryVariables = Exact<{
  id: Scalars['ID'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type CategoryByIdQuery = (
  { __typename?: 'Query' }
  & { point?: Maybe<(
    { __typename?: 'Point' }
    & Pick<Point, 'id' | 'verify'>
    & { service: (
      { __typename?: 'Service' }
      & Pick<Service, 'id'>
      & { category?: Maybe<(
        { __typename?: 'Category' }
        & ServiceCategoryFragment
      )> }
    ) }
  )> }
);

export type SliderDataQueryVariables = Exact<{
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  filter?: Maybe<ContentFilter>;
}>;


export type SliderDataQuery = (
  { __typename?: 'Query' }
  & { point?: Maybe<(
    { __typename?: 'Point' }
    & Pick<Point, 'id' | 'verify'>
    & { service: (
      { __typename?: 'Service' }
      & Pick<Service, 'id'>
      & { contents?: Maybe<Array<(
        { __typename?: 'Content' }
        & Pick<Content, 'id'>
        & SliderContentFragment
      )>> }
    ) }
  )> }
);

export type SliderContentFragment = (
  { __typename?: 'Content' }
  & { category: (
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'alias' | 'name'>
  ) }
  & ContentFragment
);

export type ContentByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ContentByIdQuery = (
  { __typename?: 'Query' }
  & { point?: Maybe<(
    { __typename?: 'Point' }
    & Pick<Point, 'id' | 'verify' | 'flowURL'>
    & { service: (
      { __typename?: 'Service' }
      & Pick<Service, 'id'>
      & { content?: Maybe<(
        { __typename?: 'Content' }
        & ContentFragment
      )> }
    ) }
  )> }
);

export type ContentIdByCategoryQueryVariables = Exact<{
  id: Scalars['ID'];
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  filter?: Maybe<ContentFilter>;
}>;


export type ContentIdByCategoryQuery = (
  { __typename?: 'Query' }
  & { point?: Maybe<(
    { __typename?: 'Point' }
    & Pick<Point, 'id'>
    & { service: (
      { __typename?: 'Service' }
      & Pick<Service, 'id'>
      & { category?: Maybe<(
        { __typename?: 'Category' }
        & Pick<Category, 'id'>
        & { contents?: Maybe<Array<(
          { __typename?: 'Content' }
          & Pick<Content, 'id' | 'type'>
        )>> }
      )> }
    ) }
  )> }
);

export const CompactCategoryFragmentDoc = gql`
    fragment CompactCategory on Category {
  id
  alias
  name
  totalContent
  priority
}
    `;
export const TagFragmentDoc = gql`
    fragment Tag on Tag {
  id
  displayName
  priority
}
    `;
export const PreviewFragmentDoc = gql`
    fragment Preview on Preview {
  id
  link
  type
}
    `;
export const ContentFragmentDoc = gql`
    fragment Content on Content {
  id
  description
  link
  name
  type
  priority
  category {
    id
    alias
    name
    totalContent
  }
  tags {
    ...Tag
  }
  previews {
    ...Preview
  }
}
    ${TagFragmentDoc}
${PreviewFragmentDoc}`;
export const ServiceCategoryFragmentDoc = gql`
    fragment ServiceCategory on Category {
  id
  alias
  name
  totalContent
  priority
  contents(limit: $limit, offset: $offset) {
    ...Content
  }
}
    ${ContentFragmentDoc}`;
export const SliderContentFragmentDoc = gql`
    fragment SliderContent on Content {
  category {
    id
    alias
    name
  }
  ...Content
}
    ${ContentFragmentDoc}`;
export const ServiceDocument = gql`
    query Service($limit: Int! = 8, $offset: Int! = 0) {
  point {
    id
    flowURL
    sskey
    verify
    service {
      id
      categories {
        ...ServiceCategory
      }
    }
  }
}
    ${ServiceCategoryFragmentDoc}`;

/**
 * __useServiceQuery__
 *
 * To run a query within a React component, call `useServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useServiceQuery(baseOptions?: Apollo.QueryHookOptions<ServiceQuery, ServiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiceQuery, ServiceQueryVariables>(ServiceDocument, options);
      }
export function useServiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiceQuery, ServiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiceQuery, ServiceQueryVariables>(ServiceDocument, options);
        }
export type ServiceQueryHookResult = ReturnType<typeof useServiceQuery>;
export type ServiceLazyQueryHookResult = ReturnType<typeof useServiceLazyQuery>;
export type ServiceQueryResult = Apollo.QueryResult<ServiceQuery, ServiceQueryVariables>;
export const CompactDocument = gql`
    query Compact {
  point {
    id
    flowURL
    sskey
    verify
    service {
      id
      categories {
        ...CompactCategory
      }
    }
  }
}
    ${CompactCategoryFragmentDoc}`;

/**
 * __useCompactQuery__
 *
 * To run a query within a React component, call `useCompactQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompactQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompactQuery(baseOptions?: Apollo.QueryHookOptions<CompactQuery, CompactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompactQuery, CompactQueryVariables>(CompactDocument, options);
      }
export function useCompactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompactQuery, CompactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompactQuery, CompactQueryVariables>(CompactDocument, options);
        }
export type CompactQueryHookResult = ReturnType<typeof useCompactQuery>;
export type CompactLazyQueryHookResult = ReturnType<typeof useCompactLazyQuery>;
export type CompactQueryResult = Apollo.QueryResult<CompactQuery, CompactQueryVariables>;
export const LoadMoreCategoryDocument = gql`
    query LoadMoreCategory($id: ID!, $limit: Int! = 8, $offset: Int! = 0) {
  point {
    id
    service {
      id
      category(id: $id) {
        ...ServiceCategory
      }
    }
  }
}
    ${ServiceCategoryFragmentDoc}`;

/**
 * __useLoadMoreCategoryQuery__
 *
 * To run a query within a React component, call `useLoadMoreCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadMoreCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadMoreCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useLoadMoreCategoryQuery(baseOptions: Apollo.QueryHookOptions<LoadMoreCategoryQuery, LoadMoreCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadMoreCategoryQuery, LoadMoreCategoryQueryVariables>(LoadMoreCategoryDocument, options);
      }
export function useLoadMoreCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadMoreCategoryQuery, LoadMoreCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadMoreCategoryQuery, LoadMoreCategoryQueryVariables>(LoadMoreCategoryDocument, options);
        }
export type LoadMoreCategoryQueryHookResult = ReturnType<typeof useLoadMoreCategoryQuery>;
export type LoadMoreCategoryLazyQueryHookResult = ReturnType<typeof useLoadMoreCategoryLazyQuery>;
export type LoadMoreCategoryQueryResult = Apollo.QueryResult<LoadMoreCategoryQuery, LoadMoreCategoryQueryVariables>;
export const CategoryByIdDocument = gql`
    query CategoryById($id: ID!, $limit: Int!, $offset: Int!) {
  point {
    id
    verify
    service {
      id
      category(id: $id) {
        ...ServiceCategory
      }
    }
  }
}
    ${ServiceCategoryFragmentDoc}`;

/**
 * __useCategoryByIdQuery__
 *
 * To run a query within a React component, call `useCategoryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useCategoryByIdQuery(baseOptions: Apollo.QueryHookOptions<CategoryByIdQuery, CategoryByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryByIdQuery, CategoryByIdQueryVariables>(CategoryByIdDocument, options);
      }
export function useCategoryByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryByIdQuery, CategoryByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryByIdQuery, CategoryByIdQueryVariables>(CategoryByIdDocument, options);
        }
export type CategoryByIdQueryHookResult = ReturnType<typeof useCategoryByIdQuery>;
export type CategoryByIdLazyQueryHookResult = ReturnType<typeof useCategoryByIdLazyQuery>;
export type CategoryByIdQueryResult = Apollo.QueryResult<CategoryByIdQuery, CategoryByIdQueryVariables>;
export const SliderDataDocument = gql`
    query SliderData($limit: Int! = 10, $offset: Int! = 0, $filter: ContentFilter) {
  point {
    id
    verify
    service {
      id
      contents(limit: $limit, offset: $offset, filter: $filter) {
        id
        ...SliderContent
      }
    }
  }
}
    ${SliderContentFragmentDoc}`;

/**
 * __useSliderDataQuery__
 *
 * To run a query within a React component, call `useSliderDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSliderDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSliderDataQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSliderDataQuery(baseOptions?: Apollo.QueryHookOptions<SliderDataQuery, SliderDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SliderDataQuery, SliderDataQueryVariables>(SliderDataDocument, options);
      }
export function useSliderDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SliderDataQuery, SliderDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SliderDataQuery, SliderDataQueryVariables>(SliderDataDocument, options);
        }
export type SliderDataQueryHookResult = ReturnType<typeof useSliderDataQuery>;
export type SliderDataLazyQueryHookResult = ReturnType<typeof useSliderDataLazyQuery>;
export type SliderDataQueryResult = Apollo.QueryResult<SliderDataQuery, SliderDataQueryVariables>;
export const ContentByIdDocument = gql`
    query ContentById($id: ID!) {
  point {
    id
    verify
    flowURL
    service {
      id
      content(id: $id) {
        ...Content
      }
    }
  }
}
    ${ContentFragmentDoc}`;

/**
 * __useContentByIdQuery__
 *
 * To run a query within a React component, call `useContentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContentByIdQuery(baseOptions: Apollo.QueryHookOptions<ContentByIdQuery, ContentByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContentByIdQuery, ContentByIdQueryVariables>(ContentByIdDocument, options);
      }
export function useContentByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContentByIdQuery, ContentByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContentByIdQuery, ContentByIdQueryVariables>(ContentByIdDocument, options);
        }
export type ContentByIdQueryHookResult = ReturnType<typeof useContentByIdQuery>;
export type ContentByIdLazyQueryHookResult = ReturnType<typeof useContentByIdLazyQuery>;
export type ContentByIdQueryResult = Apollo.QueryResult<ContentByIdQuery, ContentByIdQueryVariables>;
export const ContentIdByCategoryDocument = gql`
    query ContentIdByCategory($id: ID!, $limit: Int! = 10, $offset: Int! = 0, $filter: ContentFilter) {
  point {
    id
    service {
      id
      category(id: $id) {
        id
        contents(limit: $limit, offset: $offset, filter: $filter) {
          id
          type
        }
      }
    }
  }
}
    `;

/**
 * __useContentIdByCategoryQuery__
 *
 * To run a query within a React component, call `useContentIdByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentIdByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentIdByCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useContentIdByCategoryQuery(baseOptions: Apollo.QueryHookOptions<ContentIdByCategoryQuery, ContentIdByCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContentIdByCategoryQuery, ContentIdByCategoryQueryVariables>(ContentIdByCategoryDocument, options);
      }
export function useContentIdByCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContentIdByCategoryQuery, ContentIdByCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContentIdByCategoryQuery, ContentIdByCategoryQueryVariables>(ContentIdByCategoryDocument, options);
        }
export type ContentIdByCategoryQueryHookResult = ReturnType<typeof useContentIdByCategoryQuery>;
export type ContentIdByCategoryLazyQueryHookResult = ReturnType<typeof useContentIdByCategoryLazyQuery>;
export type ContentIdByCategoryQueryResult = Apollo.QueryResult<ContentIdByCategoryQuery, ContentIdByCategoryQueryVariables>;